import { ComponentProps, useEffect, useState } from 'react'
import { Button } from 'src/components/Button/Button'
import { QuillPreview } from 'src/components/Quill/QuillPreview'
import { contentsSelector } from 'src/store/adapters/contents'
import { useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'

import { Checkbox, FormControlLabel, styled } from '@mui/material'

export type ResponsibilityListProps = ComponentProps<typeof StyledTermsContent> & {
  onConfirmAcceptTerms: () => void
}


export const ResponsibilityList: React.FC<ResponsibilityListProps> = ({ onConfirmAcceptTerms, ...props }) => {
  const content = useAppSelector(state => contentsSelector.selectAll(state))
  const onboardingContent = content.find(c => c.content_code === 'Onboarding')?.content_data
  const [acceptTerms, setAcceptTerms] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('onboardingComplete') === 'true')
      setAcceptTerms(true)
  }, [])

  return (
    <StyledTermsContent {...props}>
      {onboardingContent && <QuillPreview htmlContent={onboardingContent} />}

      <FormControlLabel
        label="Accept Responsibilities"
        control={<Checkbox checked={acceptTerms} onChange={(ev) => { setAcceptTerms(ev.target.checked) }} />}
      />
      <Button
        primary
        fullWidth
        onClick={onConfirmAcceptTerms}
        disabled={!acceptTerms}
        sx={{ marginTop: pxToRem(16) }}
      >
        Continue
      </Button>
    </StyledTermsContent>
  )
}

const StyledTermsContent = styled('div')`
  margin-top: ${pxToRem(24)};
  margin-bottom: ${pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${pxToRem(32)};
    border-radius: ${pxToRem(16)};
    padding: ${pxToRem(16)};
    border: 1px solid ${props => props.theme.palette.text.secondary};
  }
`
