import { Location } from 'src/types/Location'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { locationsAdapter } from '../adapters/locations'

export const initialState = locationsAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    addMultipleLocations: (state, action: PayloadAction<Location[]>) => {
      locationsAdapter.upsertMany(state, action.payload)
    },
    removeAllLocations: (state) => {
      locationsAdapter.removeAll(state)
    },
  },
})

export const { addMultipleLocations, removeAllLocations } = actions