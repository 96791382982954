
import dayjs from 'dayjs'
import { useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { MobileSlider } from 'src/components/MobileSlider/MobileSlider'
import { Modal } from 'src/components/Modal/Modal'
import { Tag } from 'src/components/Tag/Tag'
import { BookingForm } from 'src/fragments/BookingForm/BookingForm'
import { ResponsibilityList } from 'src/fragments/Onboarding/ResponsibilityList'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { formatRecurrenceRule } from 'src/libs/formatRecurrenceRule'
import { hasOnboardingContent } from 'src/libs/hasOnboardingContent'
import { bookingsSelectors } from 'src/store/adapters/bookings'
import { contentsSelector } from 'src/store/adapters/contents'
import { imagesSelectors } from 'src/store/adapters/images'
import { locationsSelectors } from 'src/store/adapters/locations'
import { passengersSelectors } from 'src/store/adapters/passengers'
import { addMultipleBookings } from 'src/store/reducers/bookings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { Booking } from 'src/types/Booking'

import { faChevronLeft, faGasPump, faQuestionCircle, faSuitcase, faTimes, faUserGroup } from '@fortawesome/pro-solid-svg-icons'
import { Checkbox, Container, Divider, FormControlLabel, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

import {
  StyledAlert, StyledBreadCrumb, StyledBreadCrumbMuiLink, StyledBreadCrumbTypography, StyledCheveronButton, StyledContainer, StyledContent,
  StyledGridFullWidth, StyledHeader, StyledHeaderTypography, StyledHelpIcon, StyledIcon, StyledIconButton, StyledIconGroup, StyledIconText,
  StyledImage, StyledPrimaryTypography, StyledSecondaryTypography, StyledSliderContent, StyledTitle, StyledTitleDescription,
} from './StyledBookingDetails'

export type BookingDetailsProps = {
  bookingId: Booking['BOOKING_ID']
  onClose: () => void
}

export const BookingDetails: React.FC<BookingDetailsProps> = ({ bookingId, onClose }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const dispatch = useAppDispatch()
  const [isConfirmStartDialogOpen, setConfirmStartDialogOpen] = useState<boolean>(false)
  const [isConfirmCompleteDialogOpen, setConfirmCompleteDialogOpen] = useState<boolean>(false)
  const [isConfirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState<boolean>(false)
  const [isResponsibilityListOpen, setResponsibilityListOpen] = useState(false)
  const [isEditWithRecurrence, setEditWithRecurrence] = useState<boolean | null>(null)
  const [isEditBookingFormOpen, setEditBookingFormOpen] = useState(false)
  const [isRecurrenceCancelAll, setRecurrenceCancelAll] = useState<boolean>(false)

  const { complete, errorMessage, request } = useApiRequest()

  const user = useAppSelector(state => state.user)
  const booking = useAppSelector(state => bookingsSelectors.selectById(state, bookingId))
  const vehicleImage = useAppSelector(state => imagesSelectors.selectById(state, booking?.FILE || ''))
  const vehicle = useAppSelector(state => state.vehicles.entities[booking?.VEHICLE_ID || ''])
  const passengers = useAppSelector(state => passengersSelectors.selectAll(state))
  const onboardingContent = useAppSelector(state => contentsSelector.selectAll(state)).find(c => c.content_code === 'Onboarding')?.content_data
  const isWaipa = user.ui_type === 'WAIPA'

  const pickupTimezone = useAppSelector(state => locationsSelectors.selectById(state, booking?.LOCATION_PICKUP_ID || '')?.location_timezone)
  const dropoffTimezone = useAppSelector(state => locationsSelectors.selectById(state, booking?.LOCATION_DROPOFF_ID || '')?.location_timezone)
  const datePickup = dayjs.tz(booking?.booking_dt_pickup, pickupTimezone)
  const dateDropoff = dayjs.tz(booking?.booking_dt_dropoff, dropoffTimezone)

  const isBookingRecurrence = booking ? booking.recurrence_type.toLowerCase() !== 'none' : false
  const isBookingCancelled = booking?.booking_cancelled !== '0000-00-00 00:00:00'
  const isBookingStarted = booking?.booking_started !== '0000-00-00 00:00:00'
  const isBookingCompleted = booking?.booking_completed !== '0000-00-00 00:00:00'
  const isBookingExpired = !isBookingStarted && !isBookingCancelled && !isBookingCompleted && dateDropoff.isBefore(dayjs())

  const getFuelInitials = (fuel: string) => {
    const lowerCaseName = fuel.toLowerCase()

    if (lowerCaseName.includes('petrol')) {
      return 'P'
    }

    if (lowerCaseName.includes('diesel')) {
      return 'D'
    }

    if (lowerCaseName.includes('ev')) {
      return 'EV'
    }

    return '?'
  }

  const getTransmissionInitials = (transimission: string) => {
    const lowerCaseCodes = transimission.toLowerCase()

    if (lowerCaseCodes.includes('manual') && lowerCaseCodes.includes('auto')) {
      return 'M/A'
    }

    if (lowerCaseCodes.includes('manual')) {
      return 'M'
    }

    if (lowerCaseCodes.includes('auto')) {
      return 'A'
    }

    return 'n/a'
  }

  const startBooking = () => {
    if (!booking?.vehicle_guid || !booking?.booking_guid) return

    const handler = () => {
      return CarshareApiService.post<Booking>('startBooking', {
        'vehicle_guid': booking?.vehicle_guid,
        'booking_guid': booking?.booking_guid,
        'booking_started_iso': 'NOW',
      })
    }

    request(handler).then((res) => {
      if (res && res.results?.length > 0) {
        dispatch(addMultipleBookings(res.results))
      }

      setConfirmStartDialogOpen(false)
    })
  }

  const completeBooking = () => {
    if (!booking?.vehicle_guid || !booking?.booking_guid) return

    const handler = () => {
      return CarshareApiService.post<Booking>('completeBooking', {
        'vehicle_guid': vehicle?.vehicle_guid,
        'booking_guid': booking?.booking_guid,
        'booking_completed_iso': 'NOW',
      })
    }

    request(handler).then((res) => {
      if (res && res.results?.length > 0) {
        dispatch(addMultipleBookings(res.results))
      }

      setConfirmCompleteDialogOpen(false)
    })
  }

  const cancelBooking = () => {
    if (!booking?.vehicle_guid || !booking?.booking_guid) return

    const handler = () => {
      return CarshareApiService.post<Booking>('cancelBooking', {
        'vehicle_guid': booking?.vehicle_guid,
        'booking_guid': booking?.booking_guid,
        'recurrence_cancel_all': isRecurrenceCancelAll ? '1' : '0',
      })
    }

    request(handler).then((res) => {
      if (res && res.results?.length > 0) {
        dispatch(addMultipleBookings(res.results))
      }

      setConfirmCancelDialogOpen(false)
    })
  }

  return (
    <StyledContainer>
      {!isMobile && !complete && !errorMessage && isBookingExpired && <StyledAlert severity={'error'} icon={false}>
        This booking was not started and expired. Please cancel this booking before starting a new one.
      </StyledAlert>}

      <StyledHeader container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <StyledCheveronButton onClick={onClose}>
            <Icon icon={faChevronLeft} />
          </StyledCheveronButton>
        </Grid>
        <Grid item>
          <StyledHeaderTypography variant="body1">Booking Details</StyledHeaderTypography>
        </Grid>
        <Grid item>
          <StyledIconButton onClick={onClose}>
            <Icon icon={faTimes} />
          </StyledIconButton>
        </Grid>
      </StyledHeader>

      {isMobile && !complete && !errorMessage && isBookingExpired && <StyledAlert severity={'error'} icon={false}>
        This booking was not started and expired. Please cancel this booking before starting a new one.
      </StyledAlert>}
      {errorMessage && (
        <StyledAlert severity={'error'} icon={false}>
          {errorMessage}
        </StyledAlert>
      )}
      {complete && (
        <StyledAlert severity={'success'} icon={false}>
          Booking was updated successfully
        </StyledAlert>
      )}

      <Container maxWidth="lg">
        <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'}>
          <StyledGridFullWidth item xs={12} md={6} >
            <StyledGridFullWidth container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
              <StyledGridFullWidth item>
                <StyledImage src={vehicleImage?.file_image_src_data || '/images/car-placeholder.svg'} />
              </StyledGridFullWidth>
            </StyledGridFullWidth>
          </StyledGridFullWidth>

          <Grid item xs={12} md={6}>
            <StyledContent>
              <StyledBreadCrumb>
                <StyledBreadCrumbMuiLink onClick={onClose}>
                  Bookings
                </StyledBreadCrumbMuiLink>
                <StyledBreadCrumbTypography variant="body1">{booking?.booking_reference}</StyledBreadCrumbTypography>
              </StyledBreadCrumb>
              <StyledTitle variant="h1">
                Booking details for {booking?.vehicle_rego_number}
              </StyledTitle>
              <StyledTitleDescription variant="body1">
                with reference number <strong>{booking?.booking_reference}</strong>
              </StyledTitleDescription>

              <Grid container alignItems={'center'}>
                {vehicle?.vehicle_nr_seats && <StyledIconGroup item>
                  <StyledIcon icon={faUserGroup} />
                  <StyledIconText variant="body1">
                    {vehicle?.vehicle_nr_seats}
                  </StyledIconText>
                </StyledIconGroup>}
                {vehicle?.vehicle_nr_luggage && <StyledIconGroup item>
                  <StyledIcon icon={faSuitcase} />
                  <StyledIconText variant="body1">
                    {vehicle.vehicle_nr_luggage}
                  </StyledIconText>
                </StyledIconGroup>}
                {vehicle?.vehicle_fuel && <StyledIconGroup item>
                  <StyledIcon icon={faGasPump} />
                  <StyledIconText variant="body1">
                    {getFuelInitials(vehicle?.vehicle_fuel)}
                  </StyledIconText>
                </StyledIconGroup>}
                {vehicle?.vehicle_fuel && <StyledIconGroup item>
                  <img src={'/images/transmission.svg'} />
                  <StyledIconText variant="body1">
                    {getTransmissionInitials(vehicle?.vehicle_transmission)}
                  </StyledIconText>
                </StyledIconGroup>}
              </Grid>

              {onboardingContent && hasOnboardingContent(onboardingContent) &&
                <StyledHelpIcon
                  tooltipTitle={'Responsibilities'}
                  onClick={() => { setResponsibilityListOpen(true) }}
                >
                  <Icon icon={faQuestionCircle} />
                </StyledHelpIcon>
              }

              {isMobile ? (
                <StyledPrimaryTypography variant="body1">
                  Booking ref: {booking?.booking_reference}
                </StyledPrimaryTypography>
              ) : (
                <StyledPrimaryTypography variant="body1">
                  History
                </StyledPrimaryTypography>
              )}

              <StyledSecondaryTypography variant="body1">
                Started on: {booking?.booking_dt_started === '0000-00-00 00:00:00' ? 'N/A' : dayjs(booking?.booking_dt_started).format('D MMM YYYY, h:mm A')} {pickupTimezone && `(${pickupTimezone})`}
              </StyledSecondaryTypography>
              {isBookingCancelled && <StyledSecondaryTypography variant="body1">
                Cancelled on: {dayjs(booking?.booking_dt_cancelled).format('D MMM YYYY, h:mm A')} {pickupTimezone && `(${pickupTimezone})`}
              </StyledSecondaryTypography>}
              {isBookingCompleted && <StyledSecondaryTypography variant="body1">
                Completed on: {dayjs(booking?.booking_dt_completed).format('D MMM YYYY, h:mm A')} {dropoffTimezone && `(${dropoffTimezone})`}
              </StyledSecondaryTypography>}

              <StyledPrimaryTypography variant="body1">
                Pick up
              </StyledPrimaryTypography>
              <StyledSecondaryTypography variant="body1">
                {datePickup.format('D MMM YYYY, h:mm A')} {pickupTimezone && `(${pickupTimezone})`}
                <br />
                {booking?.location_pickup_address}
              </StyledSecondaryTypography>

              <StyledPrimaryTypography variant="body1">
                Drop off
              </StyledPrimaryTypography>
              <StyledSecondaryTypography variant="body1">
                {dateDropoff.format('D MMM YYYY, h:mm A')} {dropoffTimezone && `(${dropoffTimezone})`}
                <br />
                {booking?.location_dropoff_address}
              </StyledSecondaryTypography>

              {booking?.recurrence_type.toLowerCase() !== 'none' &&
                <>
                  <StyledPrimaryTypography variant="body1">
                    Recurrence Rule
                  </StyledPrimaryTypography>
                  <StyledSecondaryTypography variant="body1">
                    {formatRecurrenceRule(booking?.recurrence_type, booking?.recurrence_every, booking?.recurrence_until)}
                  </StyledSecondaryTypography>
                </>
              }

              {booking?.passenger_guids && (
                <>
                  <StyledPrimaryTypography variant="body1">
                    Passengers
                  </StyledPrimaryTypography>
                  <Grid container rowGap={pxToRem(4)} columnGap={pxToRem(4)} sx={{ marginTop: pxToRem(4) }}>
                    {booking.passenger_guids.split(',').map((psgGuid) => {
                      const psgName = passengers.find(psg => psg.passenger_guid === psgGuid)?.passenger_name

                      return (
                        <Tag key={psgGuid} color="neutral">
                          {psgName}
                        </Tag>
                      )
                    })}
                  </Grid>
                </>
              )}

              {isWaipa && booking?.booking_destination &&
                <>
                  <StyledPrimaryTypography variant="body1">
                    Destination
                  </StyledPrimaryTypography>
                  <StyledSecondaryTypography variant="body1">
                    {booking.booking_destination}
                  </StyledSecondaryTypography>
                </>
              }
              {booking?.booking_notes &&
                <>
                  <StyledPrimaryTypography variant="body1">
                    Booking Notes
                  </StyledPrimaryTypography>
                  <StyledSecondaryTypography variant="body1">
                    {booking.booking_notes}
                  </StyledSecondaryTypography>
                </>
              }

              {isWaipa && booking?.booking_notes &&
                <StyledPrimaryTypography variant="body1">
                  Auto start and complete is {booking.booking_autocomplete === '1' ? 'enabled' : 'disabled'} for this booking
                </StyledPrimaryTypography>
              }

              {!isBookingCompleted && ['In Use', 'Late return'].includes(booking?.status) && (
                <Button primary fullWidth sx={{ marginTop: pxToRem(24) }}
                  onClick={(ev) => {
                    ev.preventDefault()
                    setConfirmCompleteDialogOpen(true)
                  }}
                >
                  Complete Booking
                </Button>
              )}
              {!isBookingStarted && !isBookingCancelled && !isBookingExpired && ['Booked', 'Late pickup'].includes(booking?.status) && (
                <Button primary fullWidth sx={{ marginTop: pxToRem(24) }}
                  onClick={(ev) => {
                    ev.preventDefault()
                    setConfirmStartDialogOpen(true)
                  }}
                >
                  Start Booking
                </Button>
              )}
              {!isBookingExpired && !isBookingCompleted && !isBookingCancelled && <Button
                outlined
                disabled={['In Use', 'Completed on-time', 'Complete late'].includes(booking?.status)}
                fullWidth
                sx={{ marginTop: pxToRem(12) }}
                onClick={(ev) => {
                  ev.preventDefault()
                  setEditBookingFormOpen(true)
                }}
              >
                Update Booking
              </Button>}

              {!isBookingStarted && !isBookingCompleted && !isBookingCancelled && (
                <>
                  {!isMobile && !isBookingExpired && <Divider sx={{ marginTop: pxToRem(24), marginBottom: pxToRem(16) }}>
                    <span>
                      <Typography variant="body1">
                        OR
                      </Typography>
                    </span>
                  </Divider>}
                  {(!isMobile || isBookingExpired) && <Button
                    outlined
                    disabled={['In Use', 'Completed on-time', 'Complete late'].includes(booking?.status)}
                    fullWidth
                    sx={{ marginTop: pxToRem(12) }}
                    onClick={(ev) => {
                      ev.preventDefault()
                      setConfirmCancelDialogOpen(true)
                    }}
                  >
                    Cancel Booking
                  </Button>}
                </>
              )}
            </StyledContent>
          </Grid>
        </Grid>
      </Container>

      <Modal title={'Start Booking'} open={isConfirmStartDialogOpen} onClose={() => { setConfirmStartDialogOpen(false) }}>
        <Typography variant="body1">Confirm start booking</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button primary onClick={() => startBooking()}>Start Booking</Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal title={'Complete Booking'} open={isConfirmCompleteDialogOpen} onClose={() => { setConfirmCompleteDialogOpen(false) }}>
        <Typography variant="body1">Confirm complete booking</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button primary onClick={() => completeBooking()}>Complete Booking</Button>
          </Grid>
        </Grid>
      </Modal>

      {/* Booking Form Dialog & Model Slider */}
      {isMobile &&
        <MobileSlider
          wrapper
          portal="#slider"
          isOpen={isEditBookingFormOpen}
          initialTitle="Update Booking"
          onClose={() => {
            setEditBookingFormOpen(false)
          }}
        >
          <MobileSlider navigation title="Update Booking" level={0} idx={0}>
            <StyledSliderContent>
              {isBookingRecurrence ? (
                <>
                  <Typography variant="body1">Would you like to update only this booking, or all future bookings?</Typography>
                  <Grid container sx={{ marginTop: pxToRem(4) }} rowSpacing={2}>
                    <Grid item xs={12}>
                      <MobileSlider button level={0} idx={0}>
                        Update current booking
                        <MobileSlider navigation title="Update Booking" level={1} idx={1}>
                          <StyledSliderContent>
                            <BookingForm
                              bookingId={bookingId}
                              isEditForm={true}
                              isEditWithRecurrence={false}
                              isMobileSlider={true}
                              onFormSubmitSuccess={() => {
                                setEditBookingFormOpen(false)
                              }}
                            />
                          </StyledSliderContent>
                        </MobileSlider>
                      </MobileSlider>
                    </Grid>
                    <Grid item xs={12}>
                      <MobileSlider button level={0} idx={1}>
                        Update current and future bookings
                        <MobileSlider navigation title="Update Future Bookings" level={1} idx={2}>
                          <StyledSliderContent>
                            <BookingForm
                              bookingId={bookingId}
                              isEditForm={true}
                              isEditWithRecurrence={true}
                              isMobileSlider={true}
                              onFormSubmitSuccess={() => {
                                setEditBookingFormOpen(false)
                              }}
                            />
                          </StyledSliderContent>
                        </MobileSlider>
                      </MobileSlider>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <BookingForm
                  bookingId={bookingId}
                  isEditForm={true}
                  isEditWithRecurrence={false}
                  isMobileSlider={true}
                  onFormSubmitSuccess={() => {
                    setEditBookingFormOpen(false)
                  }}
                />
              )}
            </StyledSliderContent>
          </MobileSlider>
        </MobileSlider>
      }

      {!isMobile && <Modal
        title={'Update Booking'}
        open={isEditBookingFormOpen}
        onClose={() => {
          setEditBookingFormOpen(false)
          setTimeout(() => {
            setEditWithRecurrence(null)
          }, 250)
        }}
      >
        {isBookingRecurrence && isEditWithRecurrence === null && (
          <>
            <Typography variant="body1">Would you like to update only this booking, or all future bookings?</Typography>
            <Grid container sx={{ marginTop: pxToRem(4) }} rowSpacing={2}>
              <Grid item xs={12}>
                <Button
                  outlined
                  fullWidth
                  onClick={() => {
                    setEditWithRecurrence(false)
                  }}
                >
                  Update current booking
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  outlined
                  fullWidth
                  onClick={() => {
                    setEditWithRecurrence(true)
                  }}
                >
                  Update current and future bookings
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {(!isBookingRecurrence || isEditWithRecurrence !== null) && <BookingForm
          bookingId={bookingId}
          isEditForm={true}
          isEditWithRecurrence={isEditWithRecurrence || isBookingRecurrence}
          onFormSubmitSuccess={() => {
            setEditBookingFormOpen(false)
          }}
        />}
      </Modal>}

      <Modal portal={'#modal'} title={'Cancel Booking'} open={isConfirmCancelDialogOpen} onClose={() => { setConfirmCancelDialogOpen(false) }}>
        {isBookingRecurrence ? (
          <FormControlLabel
            control={<Checkbox checked={isRecurrenceCancelAll} onChange={(ev) => { setRecurrenceCancelAll(ev.target.checked) }} />}
            label="Cancel all upcoming bookings in the series"
          />
        ) : (
          <Typography variant="body1">Confirm cancel booking</Typography>
        )}
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button primary onClick={cancelBooking}>Cancel Booking</Button>
          </Grid>
        </Grid>
      </Modal>

      {/* Responsibility List Dialog & Slider */}
      <MobileSlider wrapper portal="#slider" initialTitle="Responsibility List" isOpen={isResponsibilityListOpen} onClose={() => { setResponsibilityListOpen(false) }}>
        <MobileSlider navigation level={0} idx={0}>
          <ResponsibilityList
            sx={{ padding: `0 ${pxToRem(16)}`, marginTop: 0, marginBottom: pxToRem(64) }}
            onConfirmAcceptTerms={() => {
              setResponsibilityListOpen(false)
            }} />
        </MobileSlider>
      </MobileSlider>
    </StyledContainer>
  )
}
