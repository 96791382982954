import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { ResponsibilityList } from 'src/fragments/Onboarding/ResponsibilityList'
import { hasOnboardingContent } from 'src/libs/hasOnboardingContent'
import { contentsSelector } from 'src/store/adapters/contents'
import { useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'

import { Container, Typography } from '@mui/material'

export const Onboarding = () => {
  const navigate = useNavigate()
  const { complete } = useAppSelector((state) => state.contents)
  const content = useAppSelector(state => contentsSelector.selectAll(state))
  const onboardingContent = content.find(c => c.content_code === 'Onboarding')?.content_data

  const confirmAcceptTerms = () => {
    // localStorage.setItem('onboardingComplete', 'true')
    navigate('/explore')
  }


  useEffect(() => {
    if (!complete || hasOnboardingContent(onboardingContent)) return

    // Skip onboarding if no content
    // localStorage.setItem('onboardingComplete', 'true')
    navigate('/explore')
  }, [onboardingContent, complete])

  return complete && hasOnboardingContent(onboardingContent) ? (
    <AnimatedPage>
      <Container maxWidth="md" sx={{ overflow: 'hidden', marginBottom: pxToRem(64) }}>
        <ResponsibilityList onConfirmAcceptTerms={confirmAcceptTerms} />
      </Container>
    </AnimatedPage >
  ) : (
    <Container maxWidth="md" sx={{
      overflow: 'hidden',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant="body1">
        Loading...
      </Typography>
    </Container>
  )
}
