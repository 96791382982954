import dayjs, { Dayjs } from 'dayjs'
import { ComponentProps, useState } from 'react'
import { Button } from 'src/components/Button/Button'
import { DateTimePicker } from 'src/components/DateTimePicker/DateTimePicker'
import { StyledIcon } from 'src/components/Dropdown/Dropdown'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { ToggleButton } from 'src/components/ToggleButton/ToggleButton'
import { initialFilterState, setFilterOptions } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'

import { faBarsFilter, faChartGantt, faList, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Grid, InputAdornment, ToggleButtonGroup, Typography } from '@mui/material'

import {
  StyledBadge, StyledClearFilterGrid, StyledClose, StyledDesktopGrid, StyledDesktopSearchBar, StyledExploreHeader, StyledFilterButton,
  StyledFilterRule, StyledMobileAvailabilityFilter, StyledMobileGroup, StyledMobilePlaceholder, StyledMobileSearchBar, StyledSelectDateButton,
  StyledVerticleDivider,
} from './StyledHeader'

export type HeaderProps = Omit<ComponentProps<typeof Grid>, 'container' | 'alignItems'> & {
  onToggleFilter: () => void
  onClearFilter: () => void
  setSearchValue: (value: string) => void
  setCalendarView: (value: boolean) => void
  isCalendarView: boolean
  searchValue: string
  numFilterChanges: number
  availableVehicles: number
}

export const Header: React.FC<HeaderProps> = ({
  onToggleFilter, setSearchValue, onClearFilter, setCalendarView,
  availableVehicles, searchValue, numFilterChanges, isCalendarView, ...props
}) => {
  const dispatch = useAppDispatch()
  const [pickupAnchorEl, setPickupAnchorEl] = useState<null | HTMLElement>(null)
  const [dropoffAnchorEl, setDropoffAnchorEl] = useState<null | HTMLElement>(null)
  const filterOptions = useAppSelector((state) => state.settings.filterOptions) ?? initialFilterState

  const pickupDateTime = dayjs.utc(filterOptions.fromDate)
    .set('hour', filterOptions.pickupHour)
    .set('minute', filterOptions.pickupMinute)

  const dropoffDateTime = dayjs.utc(filterOptions.toDate)
    .set('hour', filterOptions.dropoffHour)
    .set('minute', filterOptions.dropoffMinute)

  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'space-between'} {...props}>
        <Grid item>
          <StyledExploreHeader container alignItems={'center'} {...props}>
            <Grid item xs={12} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}>
              <StyledMobileSearchBar
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder={'Search...'}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ opacity: searchValue === '' ? 0 : 1 }}
                    >
                      <IconButton
                        disabled={searchValue === ''}
                        onClick={() => { setSearchValue('') }}
                      >
                        <StyledClose icon={faTimes} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledMobileGroup value={isCalendarView}>
                <ToggleButton
                  secondary
                  tooltipTitle={'List View'}
                  value={false}
                  onClick={() => setCalendarView(false)}
                >
                  <Icon sx={{ fontSize: 20 }} icon={faList} />
                </ToggleButton>
                <ToggleButton
                  secondary
                  tooltipTitle={'Calendar View'}
                  value={true}
                  onClick={() => setCalendarView(true)}
                >
                  <Icon sx={{ fontSize: 20 }} icon={faChartGantt} />
                </ToggleButton>
              </StyledMobileGroup>
            </Grid>
            <StyledMobileAvailabilityFilter item xs={12} $disabled={isCalendarView}>
              <Grid container justifyContent={'space-between'} wrap={'nowrap'}>
                <Grid item sm={'auto'}>
                  <StyledSelectDateButton
                    text
                    round
                    disabled={isCalendarView}
                    onClick={(ev) => setPickupAnchorEl(ev.currentTarget)}
                  >
                    {filterOptions.fromDate ? (
                      <Typography variant="body1" sx={{ fontWeight: 500, fontSize: pxToRem(16) }}>{pickupDateTime.format('DD/MM/YYYY HH:mm')}</Typography>
                    ) : (
                      <>
                        Select pickup date & time
                      </>
                    )}
                  </StyledSelectDateButton>
                  <DateTimePicker
                    open={Boolean(pickupAnchorEl)}
                    disablePast
                    minutesStep={15}
                    onClose={() => setPickupAnchorEl(null)}
                    defaultValue={null}
                    value={pickupDateTime}
                    slotProps={{
                      popper: {
                        anchorEl: pickupAnchorEl,
                      },
                    }}
                    onAccept={(date) => {
                      if (!date) return

                      const dateTimeUtc = dayjs.utc(date as Dayjs)

                      if (!dateTimeUtc.isValid()) return

                      dispatch(setFilterOptions({
                        fromDate: dateTimeUtc.format('YYYY-MM-DD'),
                        pickupHour: dateTimeUtc.hour(),
                        pickupMinute: dateTimeUtc.minute(),
                      }))
                    }}
                    slots={{
                      textField: () => null,
                    }}
                  />
                </Grid>
                <Grid item sm={'auto'}>
                  <StyledSelectDateButton
                    text
                    round
                    disabled={isCalendarView}
                    onClick={(ev) => setDropoffAnchorEl(ev.currentTarget)}
                    $error={(filterOptions.toDate && filterOptions.fromDate) ? dayjs(filterOptions.toDate).isBefore(dayjs(filterOptions.fromDate)) : false}
                  >
                    {filterOptions.toDate ? (
                      <Typography variant="body1" sx={{ fontWeight: 500, fontSize: pxToRem(16) }}>{dropoffDateTime.format('DD/MM/YYYY HH:mm')}</Typography>
                    ) : (
                      <>
                        Select dropoff date & time
                      </>
                    )}
                  </StyledSelectDateButton>
                  <DateTimePicker
                    open={Boolean(dropoffAnchorEl)}
                    disablePast
                    minutesStep={15}
                    minDate={dropoffDateTime}
                    value={dropoffDateTime}
                    defaultValue={null}
                    onClose={() => setDropoffAnchorEl(null)}
                    slotProps={{
                      popper: {
                        anchorEl: dropoffAnchorEl,
                      },
                    }}
                    onAccept={(date) => {
                      if (!date) return

                      const dateTimeUtc = dayjs.utc(date as Dayjs)

                      dispatch(setFilterOptions({
                        toDate: dateTimeUtc.format('YYYY-MM-DD'),
                        dropoffHour: dateTimeUtc.hour(),
                        dropoffMinute: dateTimeUtc.minute(),
                      }))
                    }}
                    slots={{
                      textField: () => null,
                    }}
                  />
                </Grid>
              </Grid>
            </StyledMobileAvailabilityFilter>
            <Grid item xs={6} sm={'auto'}>
              <StyledFilterRule>
                {availableVehicles} vehicles available
              </StyledFilterRule>
            </Grid>
            <Grid item sx={{ height: '100%' }}>
              <StyledVerticleDivider />
            </Grid>
            <Grid item>
              <StyledFilterButton
                text
                round
                disabled={isCalendarView}
                endIcon={
                  <Icon
                    sx={{ fontSize: `${pxToRem(14)} !important` }}
                    icon={faBarsFilter}
                  />
                }
                onClick={onToggleFilter}
                $numFilterChanges={numFilterChanges}
              >
                Filter
              </StyledFilterButton>
              <StyledBadge badgeContent={numFilterChanges} color="secondary" invisible={isCalendarView} />
            </Grid>
            <StyledClearFilterGrid item sx={{ height: '100%' }}>
              <StyledVerticleDivider />
            </StyledClearFilterGrid>
            <StyledClearFilterGrid item>
              <Button
                text
                round
                sx={{
                  marginLeft: pxToRem(-10),
                  marginRight: pxToRem(-14),
                }}
                disabled={numFilterChanges === 0 || isCalendarView}
                onClick={onClearFilter}
              >
                Clear Filter
              </Button>
            </StyledClearFilterGrid>
            <Grid item xs={12} sm={'auto'}>
              <StyledDesktopSearchBar
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder={'Search...'}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => { setSearchValue('') }}
                        disabled={searchValue === ''}
                        sx={{ opacity: searchValue === '' ? 0 : 1 }}
                      >
                        <StyledClose icon={faTimes} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </StyledExploreHeader>
        </Grid>
        <StyledDesktopGrid item>
          <ToggleButtonGroup value={isCalendarView}>
            <ToggleButton
              secondary
              tooltipTitle={'List View'}
              value={false}
              onClick={() => setCalendarView(false)}
            >
              <Icon sx={{ fontSize: 20 }} icon={faList} />
            </ToggleButton>
            <ToggleButton
              secondary
              tooltipTitle={'Calendar View'}
              value={true}
              onClick={() => setCalendarView(true)}
            >
              <Icon sx={{ fontSize: 20 }} icon={faChartGantt} />
            </ToggleButton>
          </ToggleButtonGroup>
        </StyledDesktopGrid>
      </Grid>
      <StyledMobilePlaceholder />
    </>
  )
}
