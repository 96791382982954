import { css, keyframes, styled } from '@mui/material/styles'

import { scrollbarStyle } from '../../styles/themes'

export const overlayLightBoxOpen = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const overlayLightBoxClose = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

export const slideRightOpen = keyframes`
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const slideRightClose = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
`

export const ModalBackground = styled('div')`
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
`

export const ModalOverlayWrapper = styled('dialog') <{
  open?: boolean,
  $fullWidth?: boolean,
  animationTime: number,
  transition: 'opacity' | 'slide-right',
  zIndex?: number
}>`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${scrollbarStyle};
  background: white;
  top: ${(props) => (props.$fullWidth ? props.theme.layout.navHeaderHeight : 0)};
  left: 0;
  z-index: ${(props) => props.zIndex ? props.zIndex : (props.theme.zIndex.appBar + (props.$fullWidth ? 100 : 105))};
  ${(props) => props.transition === 'slide-right' && css`
    animation: ${props.open ? slideRightOpen : slideRightClose} ${props.animationTime}ms ease-in-out;
  `}
  ${(props) => props.transition === 'opacity' && css`
    animation: ${props.open ? overlayLightBoxOpen : overlayLightBoxClose} ${props.animationTime}ms ease-in-out;
  `}
  transition-timing-function: ease-in-out;
  transition-duration: ${(props) => props.animationTime}ms;
  transition-property: opacity, transform;
  padding: 0;
  margin: 0;
  border: none;
  justify-content: center;
  align-items: center;
  background: transparent;

  ${(props) => !props.$fullWidth && css`
    > div {
      height: auto;
    }
  `}

  ${(props) => props.theme.breakpoints.up('sm')} {
    align-items: ${(props) => (props.$fullWidth ? 'flex-start' : 'center')};
  }
`

export const ModalOverlayContainer = styled('div',
  { shouldForwardProp: (prop) => prop !== '$fullWidth' },
) <{ $fullWidth?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.$fullWidth ? 0 : props.theme.typography.pxToRem(12)};
  width: ${(props) => (props.$fullWidth ? '100%' : '90%')};
  max-height: ${(props) => (props.$fullWidth ? '100%' : '90%')};
  height: ${(props) => (props.$fullWidth ? '100vh' : '100%')};

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: ${(props) => (props.$fullWidth ? '100%' : 'unset')};
    height: ${(props) => (props.$fullWidth ? `calc(100vh - ${props.theme.layout.navHeaderHeight})` : 'unset')};
  }
`

export const ModalMainContent = styled('div',
  { shouldForwardProp: (prop) => prop !== '$fullWidth' },
) <{ $fullWidth?: boolean }>`
  padding: ${props => props.$fullWidth ? 0 : props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(20)};
  transform: translate3d(0, 0, 0);
  overflow: auto;
  --webkit-overflow-scrolling: touch;
  ${scrollbarStyle};

  ${(props) => props.theme.breakpoints.up('sm')} {
    overflow-y: scroll;
    padding: ${props => props.theme.typography.pxToRem(12)} ${props => props.theme.typography.pxToRem(24)};
    padding-right: calc(${props => props.theme.typography.pxToRem(24)} - ${props => props.theme.layout.scrollbarWidth});
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    ${props => props.$fullWidth && css`
      overflow-y: auto;
      padding: ${props.theme.typography.pxToRem(10)} 0;
    `}
  }`
