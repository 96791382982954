import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { CalendarBooking } from 'src/components/OperationsCalander/CalendarBooking'
import { CalendarBookingList } from 'src/components/OperationsCalander/CalendarBookingList'
import { Booking, CalendarBookingRow } from 'src/components/OperationsCalander/CalendarBookingRow'
import { CalendarVehicle } from 'src/components/OperationsCalander/CalendarVehicle'
import { CalendarVehicleList } from 'src/components/OperationsCalander/CalendarVehicleList'
import { OperationsCalendar } from 'src/components/OperationsCalander/OperationsCalendar'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppSelector } from 'src/store/store'
import { ExistingBooking } from 'src/types/ExistingBooking'

import { faCalendar, faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/pro-light-svg-icons'
import { styled, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { CalendarBookingDialog } from './CalendarBookingDialog'

export type CalendarViewFragmentProps = {
  searchValue: string
  existingBookings: ExistingBooking[] | null
  currentDate: Dayjs
  setActiveVehicleId: (vehicleId: string) => void
  setCurrentDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
}

export const CalendarViewFragment: React.FC<CalendarViewFragmentProps> = ({ searchValue, currentDate, existingBookings, setActiveVehicleId, setCurrentDate }) => {
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null)
  const [dateAnchorEl, setDateAnchorEl] = useState<HTMLElement | null>(null)

  const vehicles = useAppSelector(state => vehiclesSelectors.selectAll(state)).filter((vehicle) => {
    return (vehicle.vehicle_rego_number.toLowerCase().includes(searchValue.toLowerCase()) ||
      vehicle.make_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      vehicle.model_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      vehicle.VEHICLE_ID.toLowerCase().includes(searchValue.toLowerCase()))
  })

  const onVehicleClick = (id: string | null) => {
    if (!id) return

    setActiveVehicleId(id)
  }

  // Calendar Date Selector
  const onPreviousDay = () => {
    setCurrentDate(prevDate => prevDate.subtract(1, 'day'))
  }

  const onNextDay = () => {
    setCurrentDate(prevDate => prevDate.add(1, 'day'))
  }

  return (
    <div>
      <StyledDateSelector>
        <IconButton onClick={onPreviousDay}>
          <StyledChevron icon={faChevronCircleLeft} />
        </IconButton>
        <Button text round onClick={(ev) => setDateAnchorEl(ev.currentTarget)}>
          <StyledDateTypography variant="body1">
            {currentDate.format('DD/MM/YYYY')}
          </StyledDateTypography>
          <StyledCalIcon icon={faCalendar} />
        </Button>
        <DatePicker
          open={Boolean(dateAnchorEl)}
          onClose={() => setDateAnchorEl(null)}
          defaultValue={null}
          value={dayjs(currentDate)}
          slotProps={{
            popper: {
              anchorEl: dateAnchorEl,
            },
          }}
          onAccept={(date) => {
            if (!date) return

            setCurrentDate(date)
          }}
          slots={{
            textField: () => null,
          }}
        />
        <IconButton onClick={onNextDay}>
          <StyledChevron icon={faChevronCircleRight} />
        </IconButton>
      </StyledDateSelector>
      <OperationsCalendar
        currentDay={currentDate.format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD')}
        onVehicleClick={onVehicleClick}
      >
        <CalendarVehicleList
          title={'Vehicles'}
        >
          {vehicles.map(({ model_name, make_name, vehicle_rego_number, VEHICLE_ID, file_image_src_data }, idx) => {
            const imgSrcUrl = file_image_src_data?.substring(4, file_image_src_data.length - 1) || ''

            return (
              <CalendarVehicle
                key={idx}
                vehicleId={VEHICLE_ID}
                vehicleRegoNumber={vehicle_rego_number}
                makeName={make_name}
                modelName={model_name}
                imgSrcUrl={imgSrcUrl}
              />
            )
          })}
        </CalendarVehicleList>
        <CalendarBookingList>
          {vehicles.map(({ VEHICLE_ID, vehicle_guid, vehicle_rego_number }, idx) => {
            const bookings: Booking[] = existingBookings && existingBookings
              .filter(bk => bk.vehicle_guid === vehicle_guid)
              .map((booking, idx2) => {
                const { booking_date_pickup, booking_time_pickup, booking_date_dropoff, booking_time_dropoff, customer_name, own_booking } = booking
                const [booking_time_pickup_hour, booking_time_pickup_minutes, _] = booking_time_pickup.split(':')
                const [booking_time_dropoff_hour, booking_time_dropoff_minutes, __] = booking_time_dropoff.split(':')

                const transformedBooking: Booking = {
                  id: idx2.toString(),
                  startDate: booking_date_pickup,
                  startHour: +booking_time_pickup_hour,
                  startMinute: +booking_time_pickup_minutes,
                  endDate: booking_date_dropoff,
                  endHour: +booking_time_dropoff_hour,
                  endMinute: +booking_time_dropoff_minutes,
                  customerName: customer_name,
                  status: own_booking === '1' ? 'success' : 'neutral',
                  vehicleName: vehicle_rego_number,
                  locationPickupName: booking.location_pickup_name,
                  locationDropoffName: booking.location_dropoff_name,
                  bookingDestination: booking.booking_destination,
                }

                return transformedBooking
              })
              .filter((booking) => booking !== null) || []

            return (
              <CalendarBookingRow
                key={idx}
                bookings={bookings}
                vehicleId={VEHICLE_ID}
              >
                {({ bookingsForThisHour }) => (
                  bookingsForThisHour.map((booking, idx2) => {
                    return (
                      <CalendarBooking
                        key={idx2}
                        booking={booking}
                        onClick={() => setSelectedBooking(booking)}
                      />
                    )
                  })
                )}
              </CalendarBookingRow>
            )
          })}
        </CalendarBookingList>
        <CalendarBookingDialog
          booking={selectedBooking}
          setBooking={setSelectedBooking}
        />
      </OperationsCalendar >
    </div>
  )
}

const StyledDateSelector = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
`

const StyledChevron = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
`


const StyledDateTypography = styled(Typography)`
  font-weight: 500;
  margin-right: ${props => props.theme.typography.pxToRem(4)};
`

const StyledCalIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  margin-bottom: ${props => props.theme.typography.pxToRem(2)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(20)};
  }
`