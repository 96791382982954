import dayjs from 'dayjs'

export const formatDateAndTime = (dateStr: string, hour: number, minute: number, isTimeOnly?: boolean): string => {
  const date = new Date(dateStr)

  date.setHours(hour)
  date.setMinutes(minute)

  if (isTimeOnly) return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`
}

export const formatDay = (day: Date) => {
  return day.toISOString().split('T')[0]
}


export const createDayjsObject = (date: string, hour: number, minute: number) => {
  return dayjs(`${date}T${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00`)
}