import { ToggleButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import { pxToRem } from '../../styles/themes'

export const StyledToggleButton = styled(ToggleButton)<{ $round?: boolean }>(({ theme, $round }) => ({
  borderRadius: $round ? '1000px' : theme.shape.borderRadius * 2,
  
  color: theme.palette.text.primary,
  height: pxToRem(48),
  gap: theme.spacing(1.2),
  padding: pxToRem(12),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'none',
  fontSize: pxToRem(14),
  fontWeight: 400,

  ':active': {
    fontWeight: 500,
  },

  [theme.breakpoints.up('sm')]: {
    border: `1px solid ${theme.palette.divider}`,
    fontSize: pxToRem(16),
  },
}))
