import { useRef, useState } from 'react'

import { faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { Grid, styled, Typography } from '@mui/material'

import { pxToRem } from '../../styles/themes'
import { TextField } from '../Form/TextField'
import { Icon } from '../Icon/Icon'
import { IconButton } from '../IconButton/IconButton'
import { useVehicleSearchContext } from './VehicleSearchContext'

export type CalendarVehicleListProps = {
  children: React.ReactNode
  title: string
}

export const CalendarVehicleList: React.FC<CalendarVehicleListProps> = ({ children, title }) => {
  const [vehicleSearchOpen, setVehicleSearchOpen] = useState(false)
  const { vehicleSearchString, setVehicleSearchString } = useVehicleSearchContext()
  const searchRef = useRef()

  const onSearchBlur = () => {
    if (!vehicleSearchString) {
      setVehicleSearchOpen(false)
    }
  }

  const onSearchClose: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()

    setVehicleSearchString('')
    setVehicleSearchOpen(false)
  }

  const onSearchOpen: React.MouseEventHandler<HTMLDivElement> = () => {
    setVehicleSearchOpen(true)

    if (searchRef && searchRef.current) {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (searchRef.current as any).focus()
      }, 200)
    }
  }

  return (
    <>
      <StyledVehicleListColumn container flexDirection={'column'} id="calendarVehicleListColumn">
        <StyledTitleContainer item onClick={onSearchOpen}>
          <StyledSearchIcon icon={faSearch} />
          <StyledTitle variant="body1">
            Search {title}
          </StyledTitle>
        </StyledTitleContainer>
        {children}
      </StyledVehicleListColumn>
      <StyledSearchField
        autoFocus
        inputRef={searchRef}
        $visible={vehicleSearchOpen}
        value={vehicleSearchString}
        onChange={(event) => { setVehicleSearchString(event.target.value) }}
        onBlur={onSearchBlur}
        InputProps={{
          startAdornment: (
            <StyledSearchIcon sx={{ paddingLeft: pxToRem(16) }} icon={faSearch} />
          ),
          endAdornment: (
            <IconButton
              onClick={onSearchClose}
            >
              <Icon sx={{ fontSize: pxToRem(16) }} icon={faXmark} />
            </IconButton>
          ),
        }}
      >
      </StyledSearchField>
    </>
  )
}

export const StyledVehicleListColumn = styled(Grid)`
  z-index: 4;
  background-color: ${props => props.theme.palette.background.paper};
  height: calc(100% + ${props => props.theme.typography.pxToRem(62)});
  border-right: 1px solid ${props => props.theme.palette.divider};

  width: ${props => props.theme.typography.pxToRem(186)};
  > * {
    width: ${props => props.theme.typography.pxToRem(186)};
  }

  > div {
    border-bottom: 1px solid ${props => props.theme.palette.divider};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    position: sticky;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.04);
    border-radius: ${props => props.theme.typography.pxToRem(20)} 0 0 ${props => props.theme.typography.pxToRem(20)};

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    width: ${props => props.theme.typography.pxToRem(240)};
    > * {
      width: ${props => props.theme.typography.pxToRem(240)};
    }

  }

  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.theme.typography.pxToRem(280)};
    > * {
      width: ${props => props.theme.typography.pxToRem(280)};
    }
  }
`

const StyledTitleContainer = styled(Grid)`
  position: relative;
  display: flex;
  height: ${props => props.theme.typography.pxToRem(62)};
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  border-right: 1px solid ${props => props.theme.palette.divider};
  gap: ${props => props.theme.typography.pxToRem(10)};
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  .MuiSvgIcon-root {
    color: ${props => props.theme.palette.text.primary};
  }

  .MuiTypography-root {
    color: ${props => props.theme.palette.text.primary};
  }

  &:hover {
    background-color: ${props => props.theme.palette.action.hover};
  }

  ${props => props.theme.breakpoints.up('sm')} {
    position: sticky;
    top: 0;
    border-radius: ${props => props.theme.typography.pxToRem(20)} 0 0 0;
    background-color: ${props => props.theme.palette.background.paper};
    z-index: 10;
  }
`

const StyledSearchIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(20)};
`

const StyledTitle = styled(Typography)`
  font-weight: 400;
`

const StyledSearchField = styled(TextField, { shouldForwardProp: (prop) => prop !== '$visible' }) <{ $visible: boolean }>`
  position: absolute;
  visibility: ${props => props.$visible ? 'visible' : 'hidden'};
  opacity: ${props => props.$visible ? 1 : 0};
  left: 0;
  top: 0;
  height: ${props => props.theme.typography.pxToRem(62)};
  width: ${props => props.theme.typography.pxToRem(186)};
  z-index: 40;
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: ${props => props.theme.typography.pxToRem(8)};
  border-color: ${(props) => props.theme.palette.text.primary};
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.text.primary};
  }

  .MuiOutlinedInput-root {
    height: ${props => props.theme.typography.pxToRem(63)};
    border-radius: ${props => props.theme.typography.pxToRem(8)};
  }

  .Mui-focused fieldset {
    border-color: ${(props) => props.theme.palette.text.primary};
    box-shadow: none;
  }

  .Mui-focused {
    .MuiSvgIcon-root {
      color: ${props => props.theme.palette.text.primary};
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(240)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    width: ${props => props.theme.typography.pxToRem(280)};
  }
`
