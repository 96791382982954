import { MouseEventHandler, useEffect, useRef } from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Box, Grid, styled, Typography } from '@mui/material'

import { pxToRem } from '../../styles/themes'
import { Icon } from '../Icon/Icon'
import { useCalendarContext } from './CalenderContext'

export type CalendarVehicleProps = {
  imgSrcUrl?: string
  makeName: string
  modelName: string
  vehicleRegoNumber: string
  vehicleColorCode?: string
  vehicleId: string
}

export const CalendarVehicle: React.FC<CalendarVehicleProps> = ({ imgSrcUrl, vehicleRegoNumber, vehicleId, vehicleColorCode, makeName, modelName }) => {
  const { hoveredVehicleId, setHoveredVehicleId, selectedVehicleId, setSelectedVehicleId } = useCalendarContext()
  const vehicleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (vehicleRef && vehicleRef.current) {
      vehicleRef.current.addEventListener('mouseover', () => {
        setHoveredVehicleId(vehicleId)
      })

      vehicleRef.current.addEventListener('mouseout', () => {
        setHoveredVehicleId('')
      })
    }
  }, [vehicleId, vehicleRef, vehicleRef.current])


  const onVehicleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (selectedVehicleId === vehicleId) {
      setSelectedVehicleId('')
    } else {
      setSelectedVehicleId(vehicleId)
    }
  }

  return (
    <StyledCalendarVehicleGrid
      item
      ref={vehicleRef}
      $active={hoveredVehicleId === vehicleId || selectedVehicleId === vehicleId}
      onClick={onVehicleClick}
    >
      <StyledDetails container flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} wrap="nowrap">
        {vehicleColorCode && <StyledVehicleColorCode $colorCode={vehicleColorCode} />}
        <Box sx={{ width: pxToRem(4) }} />
        {imgSrcUrl && <StyledImageContainer item>
          <StyledImage src={imgSrcUrl} />
        </StyledImageContainer>}
        <Grid item sx={{ maxWidth: `calc(100% - ${imgSrcUrl ? pxToRem(78) : pxToRem(0)})` }}>
          <StyledTitle variant="body1">
            {makeName} {modelName}
          </StyledTitle>
          <StyledRegoId variant="body1">
            {vehicleRegoNumber}
          </StyledRegoId>
        </Grid>
        <StyledIcon $active={hoveredVehicleId === vehicleId || selectedVehicleId === vehicleId} icon={faChevronRight} />
      </StyledDetails>
    </StyledCalendarVehicleGrid>
  )
}

const StyledVehicleColorCode = styled('div') <{ $colorCode: string }>`
  position: absolute;
  left: ${props => props.theme.typography.pxToRem(-8)};
  width: ${props => props.theme.typography.pxToRem(4)};
  height: calc(100% + ${props => props.theme.typography.pxToRem(16)});
  background-color: ${props => props.$colorCode};
  margin-right: ${props => props.theme.typography.pxToRem(4)};
`

const StyledIcon = styled(Icon, { shouldForwardProp: (prop) => prop !== '$active' }) <{ $active: boolean }>`
  position: absolute;
  opacity: 0;
  font-size: ${props => props.theme.typography.pxToRem(20)};
  right: ${props => props.theme.typography.pxToRem(4)};
  color: ${props => props.theme.palette.background.paper};
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${props => props.$active && `
    opacity: 1;
  `}
`

const StyledCalendarVehicleGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== '$active' }) <{ $active: boolean }>`
  height: ${props => props.theme.typography.pxToRem(62)};
  width: 100%;
  padding: ${props => props.theme.typography.pxToRem(8)};
  background-color: ${props => props.$active ? props.theme.palette.primary.main : 'transparent'};
  color: ${props => props.$active ? props.theme.palette.background.paper : 'inherit'};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
`

const StyledDetails = styled(Grid)`
  position: relative;
  height: 100%;
`

const StyledImageContainer = styled(Grid)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.typography.pxToRem(8)};
`

const StyledImage = styled('img')`
  max-height: 100%;
  width: ${props => props.theme.typography.pxToRem(60)};
  background-color: ${props => props.theme.palette.background.paper};
`

const StyledTitle = styled(Typography)`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const StyledRegoId = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
`
