import { Content } from 'src/types/Content'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContentsAdapter } from '../adapters/contents'
import { setupContents } from '../thunks/setupContent'

export const initialState = ContentsAdapter.getInitialState({
  loading: false,
  complete: false,
  error: null,
})

export const { reducer, actions } = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    addMultipleContents: (state, action: PayloadAction<Content[]>) => {
      ContentsAdapter.upsertMany(state, action.payload)
    },
    replaceAllContents: (state, action: PayloadAction<Content[]>) => {
      ContentsAdapter.setAll(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupContents.pending, (state) => {
        state.loading = true
      })
      .addCase(setupContents.fulfilled, (state, _) => {
        state.loading = false
        state.complete = true
      })
  },
})

export const { addMultipleContents, replaceAllContents } = actions