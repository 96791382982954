import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Button } from 'src/components/Button/Button'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Modal } from 'src/components/Modal/Modal'
import { MultiselectDropdown, MultiselectDropdownOption } from 'src/components/MultiselectDropdown/MultiselectDropdown'
import { MultiselectDropdownItem } from 'src/components/MultiselectDropdown/MultiselectDropdownItem'
import { TimePicker } from 'src/components/TimePicker/TimePicker'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { bookingsSelectors } from 'src/store/adapters/bookings'
import { passengersSelectors } from 'src/store/adapters/passengers'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { addMultipleBookings } from 'src/store/reducers/bookings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupBookings } from 'src/store/thunks/setupBookings'
import { pxToRem } from 'src/styles/themes'
import { Booking } from 'src/types/Booking'
import { Passenger } from 'src/types/Passenger'
import { Select } from 'src/types/Select'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'

import { FrequencyType, getInitialRecurrence, RecurrenceContext, RecurrenceType } from '../../components/Recurrence/RecurrenceContext'
import { RecurrenceSelector } from '../../components/Recurrence/RecurrenceSelector'
import { StyledCancelBookingButton, StyledDivider, StyledForm, StyledFormControl } from './StyledForm'

export type BookingFormProps = {
  bookingId?: string
  vehicleId?: string
  isMobileSlider?: boolean
  isEditForm: boolean
  isEditWithRecurrence?: boolean
  onFormSubmitSuccess?: () => void
}

export const BookingForm: React.FC<BookingFormProps> = ({ vehicleId, bookingId, isEditForm, isEditWithRecurrence, isMobileSlider, onFormSubmitSuccess }) => {
  const isWaipa = useAppSelector(state => state.user.ui_type === 'WAIPA')
  const vehicle = useAppSelector((state) => vehiclesSelectors.selectById(state, vehicleId || ''))
  const booking = useAppSelector((state) => bookingsSelectors.selectById(state, bookingId || ''))
  const passengers = useAppSelector((state) => passengersSelectors.selectAll(state))

  const isBookingRecurrence = booking?.recurrence_type.toLocaleLowerCase() !== 'none'
  const isBookingStarted = booking?.booking_started !== '0000-00-00 00:00:00'
  const isBookingCompleted = booking?.booking_completed !== '0000-00-00 00:00:00'
  const isBookingCancelled = booking?.booking_cancelled !== '0000-00-00 00:00:00'

  const filterOptions = useAppSelector((state) => state.settings.filterOptions)
  const [isUnderOneDay, setIsUnderOneDay] = useState<boolean>(false)
  const [pickupDate, setPickupDate] = useState<string | null>(null)
  const [pickupHour, setPickupHour] = useState<string | null>(null)
  const [pickupMinute, setPickupMinute] = useState<string | null>(null)
  const [dropoffDate, setDropoffDate] = useState<string | null>(null)
  const [dropoffHour, setDropoffHour] = useState<string | null>(null)
  const [dropoffMinute, setDropoffMinute] = useState<string | null>(null)
  const [recurrenceRule, setRecurrenceRule] = useState<RecurrenceType>(getInitialRecurrence())
  const [selectedPassengers, setSelectedPassengers] = useState<MultiselectDropdownOption[]>([])
  const [bookingNotes, setBookingNotes] = useState<string | null>(null)
  const [destination, setDestination] = useState<string | null>(null)
  const [isBookingAutocomplete, setBookingAutocomplete] = useState<boolean>(true)
  const [isRecurrenceCancelAll, setRecurrenceCancelAll] = useState<boolean>(false)
  const [isConfirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState<boolean>(false)

  const [pickupTimeErrorMessage, setPickupTimeErrorMessage] = useState<string | null>(null)
  const [dropoffTimeErrorMessage, setDropoffTimeErrorMessage] = useState<string | null>(null)

  const [destinationOptions, setDestinationOptions] = useState<Select['tuples']>([])

  const { loading, complete, errorMessage, request } = useApiRequest()
  const dispatch = useAppDispatch()
  const { setSuccessMessage } = useAlertContext()

  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'carshare_destinations',
    }).then((response) => {
      const carshareDestinations = response.results?.find(result => result.name === 'carshare_destinations')?.tuples || []

      carshareDestinations.unshift({ name: '', text: 'None' })

      setDestinationOptions(carshareDestinations)
      setDestination((prev) => prev ? prev : (carshareDestinations?.[0]?.name || ''))
    })
  }, [])


  const cancelBooking = () => {
    if (!booking?.vehicle_guid || !booking?.booking_guid) return

    const handler = () => {
      return CarshareApiService.post<Booking>('cancelBooking', {
        'vehicle_guid': booking?.vehicle_guid,
        'booking_guid': booking?.booking_guid,
        'recurrence_cancel_all': isRecurrenceCancelAll ? '1' : '0',
      })
    }

    request(handler).then((res) => {
      if (res && res.results?.length > 0) {
        dispatch(addMultipleBookings(res.results))
      }

      setConfirmCancelDialogOpen(false)
    })
  }


  const submitHandler = () => {
    const payload = {
      booking_date_pickup: pickupDate,
      booking_time_pickup: pickupHour + ':' + pickupMinute + ':00',
      booking_date_dropoff: dropoffDate,
      booking_time_dropoff: dropoffHour + ':' + dropoffMinute + ':00',
      booking_notes: bookingNotes,
      booking_destination: destination,
      booking_autocomplete: isBookingAutocomplete ? '1' : '0',
      recurrence_type: isUnderOneDay ? recurrenceRule.frequency : FrequencyType.None,
      recurrence_every: recurrenceRule.numberOfRepetitions,
      recurrence_until: recurrenceRule.endDate?.format('YYYY-MM-DD'),
      passenger_guids: selectedPassengers.map((item) => item.name).join(','),
    }

    if (isEditForm) {
      const updatePayload = {
        ...payload,
        booking_guid: booking.booking_guid,
        recurrence_update_all: isEditWithRecurrence ? '1' : '0',
      }

      return CarshareApiService.post('updateBooking', updatePayload)
    }

    const createPayload = {
      ...payload,
      vehicle_guid: vehicle.vehicle_guid,
      location_pickup_guid: vehicle.location_guid,
      location_dropoff_guid: vehicle.location_guid,
    }

    return CarshareApiService.post('createBooking', createPayload)
  }

  const getInValidDropoff = () => {
    if (!pickupDate || !dropoffDate || !pickupHour || !pickupMinute || !dropoffHour || !dropoffMinute) return false

    // Check for dropoff hour and minute as well
    const tempPickupDate = new Date(pickupDate)

    tempPickupDate.setHours(+pickupHour)
    tempPickupDate.setMinutes(+pickupMinute)

    const tempDropoffDate = new Date(dropoffDate)

    tempDropoffDate.setHours(+dropoffHour)
    tempDropoffDate.setMinutes(+dropoffMinute)

    return tempDropoffDate <= tempPickupDate
  }
  const isInValidDropoff = getInValidDropoff()

  const onSelectPassenger = (val: MultiselectDropdownOption) => {
    const index = selectedPassengers.findIndex(item => item.name == val.name)

    if (index > -1) {
      const newSelectedItems = [...selectedPassengers]

      newSelectedItems.splice(index, 1)
      setSelectedPassengers(newSelectedItems)
    } else {
      setSelectedPassengers([...selectedPassengers, val])
    }
  }

  useEffect(() => {
    const underOneDayCheck = !pickupDate || !dropoffDate || (pickupDate === dropoffDate)

    setIsUnderOneDay(underOneDayCheck)
  }, [pickupDate, dropoffDate])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  //Initialize form from filter options
  useEffect(() => {
    if (isEditForm) return

    if (filterOptions?.fromDate)
      setPickupDate(filterOptions.fromDate)

    if (filterOptions?.toDate)
      setDropoffDate(filterOptions.toDate)

    if (filterOptions?.pickupHour || filterOptions?.pickupHour === 0)
      setPickupHour(filterOptions.pickupHour.toString().padStart(2, '0'))

    if (filterOptions?.pickupMinute || filterOptions?.pickupMinute === 0)
      setPickupMinute(filterOptions.pickupMinute.toString().padStart(2, '0'))

    if (filterOptions?.dropoffHour || filterOptions?.dropoffHour === 0)
      setDropoffHour(filterOptions.dropoffHour.toString().padStart(2, '0'))

    if (filterOptions?.dropoffMinute || filterOptions?.dropoffMinute === 0)
      setDropoffMinute(filterOptions.dropoffMinute.toString().padStart(2, '0'))

    if (filterOptions?.fromDate && filterOptions?.toDate) {
      setRecurrenceRule((prev) => {
        return {
          ...prev,
          startDate: dayjs.utc(filterOptions.fromDate),
          endDate: dayjs.utc(filterOptions.toDate),
          frequency: FrequencyType.None,
          numberOfRepetitions: 1,
        }
      })
    }
  }, [
    filterOptions?.fromDate, filterOptions?.toDate, filterOptions?.pickupHour,
    filterOptions?.pickupMinute, filterOptions?.dropoffHour, filterOptions?.dropoffMinute,
  ])


  //Initialize edit form
  useEffect(() => {
    if (!isEditForm) return
    setPickupDate(booking?.booking_date_pickup)
    setDropoffDate(booking?.booking_date_dropoff)
    setRecurrenceRule((prev) => {
      return {
        ...prev,
        startDate: dayjs.utc(booking?.booking_date_pickup),
        frequency: (booking?.recurrence_type.toLowerCase() as FrequencyType) || FrequencyType.None,
        numberOfRepetitions: booking?.recurrence_every ? booking?.recurrence_every : 1,
        endDate: booking?.recurrence_until ? dayjs.utc(booking?.recurrence_until) : null,
      }
    })

    setPickupHour(booking.booking_time_pickup_hour)
    setPickupMinute(booking.booking_time_pickup_minutes)

    setDropoffHour(booking.booking_time_dropoff_hour)
    setDropoffMinute(booking.booking_time_dropoff_minutes)

    setDestination(booking.booking_destination)
    setBookingNotes(booking.booking_notes)

    const passengerGuids = booking.passenger_guids.split(',')
    const psgs = passengerGuids
      .map((psgGuid) => passengers.find((psg) => psg.passenger_guid === psgGuid) || null)
      .filter(item => item !== null) as Passenger[]

    setSelectedPassengers(psgs.map((item) => ({
      name: item.passenger_guid,
      label: item.passenger_name,
    })))

    setBookingAutocomplete(booking.booking_autocomplete === '1')
  }, [booking])

  // Update Recurrence Rule Date when date and time fields change
  useEffect(() => {
    if (!pickupDate || !pickupHour || !pickupMinute || !dropoffDate || !dropoffHour || !dropoffMinute) return

    setRecurrenceRule((prev) => {
      return {
        ...prev,
        startDate: dayjs.utc(`${pickupDate} ${pickupHour}:${pickupMinute}`, 'YYYY-MM-DD HH:mm'),
        endDate: dayjs.utc(`${dropoffDate} ${dropoffHour}:${dropoffMinute}`, 'YYYY-MM-DD HH:mm'),
      }
    })
  }, [pickupDate, pickupHour, pickupMinute, dropoffDate, dropoffHour, dropoffMinute])

  useEffect(() => {
    if (errorMessage) {
      const node = document.querySelector('#__modal_main')

      node?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      return
    }
  }, [errorMessage])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Booking was ${isEditForm ? 'updated' : 'created'} successfully.`)
      dispatch(setupBookings())

      if (onFormSubmitSuccess) {
        onFormSubmitSuccess()
      }
    }
  }, [complete])

  return (
    <StyledForm $isEditable onSubmit={onSubmit}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item xs={12}>
          {errorMessage && (
            <Alert id="__form_alert_error" severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl required>
            <TextFieldLabel>Pickup Date</TextFieldLabel>
            <DatePicker
              value={dayjs.utc(pickupDate)}
              format="DD/MM/YYYY"
              disablePast={!isEditForm}
              onChange={(date) => {
                if (date && (date as Dayjs)?.isValid()) {
                  const dateUtc = dayjs.utc(date as Dayjs)

                  setPickupDate(dateUtc.format('YYYY-MM-DD'))

                  if (dayjs.utc(dropoffDate).isBefore(dateUtc)) {
                    setDropoffDate(dateUtc.format('YYYY-MM-DD'))
                  }
                  
                  setRecurrenceRule((prev) => {
                    return {
                      ...prev,
                      startDate: dateUtc,
                    }
                  })
                }
              }}
              onAccept={(date) => {
                if (date) {
                  const dateUtc = dayjs.utc(date as Dayjs)

                  setPickupDate(dateUtc.format('YYYY-MM-DD'))
                  setRecurrenceRule((prev) => {
                    return {
                      ...prev,
                      startDate: dateUtc,
                    }
                  })
                }
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl required>
            <TextFieldLabel>Pickup Time</TextFieldLabel>
            <TimePicker
              views={['hours', 'minutes']}
              minutesStep={15}
              value={pickupHour && pickupMinute && dayjs.utc(`${pickupHour}:${pickupMinute}`, 'HH:mm')}
              onChange={(time) => {
                if (!time || !(time as Dayjs).isValid()) return

                const timeUtc = dayjs.utc(time as Dayjs)

                setPickupHour(timeUtc.hour().toString().padStart(2, '0'))
                setPickupMinute(timeUtc.minute().toString().padStart(2, '0'))
              }}
              onAccept={(time) => {
                const timeUtc = dayjs.utc(time as Dayjs)

                setPickupHour(timeUtc.hour().toString().padStart(2, '0'))
                setPickupMinute(timeUtc.minute().toString().padStart(2, '0'))
              }}
              onError={(error) => {
                if (!error) {
                  setPickupTimeErrorMessage(null)
                } else if (error === 'invalidDate') {
                  setPickupTimeErrorMessage('Invalid Time')
                } else if (error === 'minutesStep') {
                  setPickupTimeErrorMessage('Minutes must be in 15 minute intervals (00, 15, 30, 45).')
                }
              }}
              error={pickupTimeErrorMessage}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl required
            error={isInValidDropoff}
          >
            <TextFieldLabel>Dropoff Date</TextFieldLabel>
            <DatePicker
              value={dayjs.utc(dropoffDate)}
              format="DD/MM/YYYY"
              disablePast={!isEditForm}
              onChange={(date) => {
                if (date && (date as Dayjs)?.isValid()) {
                  setDropoffDate(dayjs.utc(date as Dayjs).format('YYYY-MM-DD'))
                }
              }}
              onAccept={(date) => {
                if (date) {
                  setDropoffDate((date as Dayjs).format('YYYY-MM-DD'))
                }
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl
            error={isInValidDropoff}
            required
          >
            <TextFieldLabel>Dropoff Time</TextFieldLabel>
            <TimePicker
              views={['hours', 'minutes']}
              minutesStep={15}
              value={dropoffHour && dropoffMinute && dayjs.utc(`${dropoffHour}:${dropoffMinute}`, 'HH:mm')}
              onChange={(time) => {
                if (!time || !(time as Dayjs).isValid()) return

                const timeUtc = dayjs.utc(time as Dayjs)

                setDropoffHour(timeUtc.hour().toString().padStart(2, '0'))
                setDropoffMinute(timeUtc.minute().toString().padStart(2, '0'))
              }}
              onAccept={(time) => {
                const timeUtc = dayjs.utc(time as Dayjs)

                setDropoffHour(timeUtc.hour().toString().padStart(2, '0'))
                setDropoffMinute(timeUtc.minute().toString().padStart(2, '0'))
              }}
              onError={(error) => {
                if (!error) {
                  setDropoffTimeErrorMessage(null)
                } else if (error === 'invalidDate') {
                  setDropoffTimeErrorMessage('Invalid Time')
                } else if (error === 'minutesStep') {
                  setDropoffTimeErrorMessage('Minutes must be in 15 minute intervals (00, 15, 30, 45).')
                }
              }}
              error={dropoffTimeErrorMessage}
            />
          </StyledFormControl>
        </Grid>
        {(!isEditForm || isEditWithRecurrence) && isUnderOneDay && <Grid item>
          <RecurrenceContext.Provider value={{ recurrence: recurrenceRule, setRecurrence: setRecurrenceRule }}>
            <StyledFormControl>
              <RecurrenceSelector
                isEditForm={isEditForm}
                isMobileSlider={isMobileSlider}
              />
            </StyledFormControl>
          </RecurrenceContext.Provider>
        </Grid>}
        {isWaipa && <Grid item xs={12}>
          <StyledFormControl>
            <TextFieldLabel>Destination</TextFieldLabel>
            <Dropdown
              value={destinationOptions.find(option => option.name === destination)?.text || ''}
              onSelectItem={(value) => { setDestination(value) }}
            >
              {destinationOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>}
        <Grid item xs={12}>
          <StyledFormControl>
            <TextFieldLabel>Passengers</TextFieldLabel>
            <MultiselectDropdown
              values={selectedPassengers}
              onSelectItem={onSelectPassenger}
            >
              {passengers.map((passenger) => (
                <MultiselectDropdownItem
                  key={passenger.passenger_guid}
                  value={{
                    name: passenger.passenger_guid,
                    label: passenger.passenger_name,
                  }}
                >
                  {passenger.passenger_name}
                </MultiselectDropdownItem>
              ))}
            </MultiselectDropdown>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledFormControl>
            <TextFieldLabel>Booking Notes</TextFieldLabel>
            <TextField
              onChange={(event) => {
                setBookingNotes(event.target.value)
              }}
              value={bookingNotes || ''}
              multiline
              rows={2}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={isBookingAutocomplete} onChange={(ev) => { setBookingAutocomplete(ev.target.checked) }} />}
            label="Auto start and complete booking"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ marginTop: pxToRem(8), marginBottom: pxToRem(isEditForm ? 8 : 16) }}
            primary
            fullWidth
            type="submit"
            disabled={loading || isInValidDropoff || !pickupDate || !dropoffDate || !pickupHour || !dropoffHour || (recurrenceRule.endDate ? recurrenceRule.endDate < recurrenceRule.startDate : false)}
          >
            {isEditForm ? 'Update Booking' : 'Book Now'}
          </Button>
          {isEditForm && !isBookingStarted && !isBookingCompleted && !isBookingCancelled &&
            <>
              <StyledDivider sx={{ marginTop: pxToRem(8), marginBottom: pxToRem(16) }}>
                <span>
                  <Typography variant="body1">
                    OR
                  </Typography>
                </span>
              </StyledDivider>
              <StyledCancelBookingButton
                outlined
                fullWidth
                disabled={loading || ['In Use', 'Completed on-time', 'Complete late'].includes(booking?.status)}
                onClick={() => { setConfirmCancelDialogOpen(true) }}
              >
                Cancel booking
              </StyledCancelBookingButton>
            </>
          }
        </Grid>
      </Grid>
      <Modal portal={'#slider'} zIndex={9999} title={'Cancel Booking'} open={isConfirmCancelDialogOpen} onClose={() => { setConfirmCancelDialogOpen(false) }}>
        {isBookingRecurrence ? (
          <FormControlLabel
            control={<Checkbox checked={isRecurrenceCancelAll} onChange={(ev) => { setRecurrenceCancelAll(ev.target.checked) }} />}
            label="Cancel all upcoming bookings in the series"
          />
        ) : (
          <Typography variant="body1">Confirm cancel booking</Typography>
        )}
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item xs={12}>
            <Button primary onClick={() => cancelBooking()}>Cancel Booking</Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledForm >
  )
}
