import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { StyledDivider } from 'src/components/Form/StyledForm'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Link } from 'src/components/Link/Link'
import { LoginCard } from 'src/components/LoginCard/LoginCard'
import { LoginRoute } from 'src/fragments/Route/LoginRoute'
import { RequestHandler, SuccessHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupUser } from 'src/store/thunks/setupUser'
import { pxToRem } from 'src/styles/themes'
import { User } from 'src/types/User'

import { Alert, FormControl, styled, Typography } from '@mui/material'

export type LoginPageProps = {
  //
}

export const LoginPage: React.FC<LoginPageProps> = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { loading, complete, errorMessage, request } = useApiRequest()
  const user = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const onLogin = useCallback(async () => {
    const loginHandler: RequestHandler<User> = () => (
      CarshareApiService.post('login', {
        username: email,
        password: password,
      })
    )

    const onSuccess: SuccessHandler<User> = (users) => {
      dispatch(setupUser(users[0]))
    }

    await request(loginHandler, onSuccess)
  }, [email, password, dispatch, request])

  useEffect(() => {
    if (complete && user.sessionid !== null) {
      // navigate to onboarding page
      if (localStorage.getItem('onboardingComplete') !== 'true') {
        navigate('/onboarding')

        return
      }

      navigate(location.state?.from ?? '/explore')
    }
  }, [user, complete])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onLogin()
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  return (
    <LoginRoute>
      <StyledLoginPage>
        <LoginCard>
          {complete && (
            <StyledAlert severity={'success'} icon={false}>
              Login Successful
            </StyledAlert>
          )}
          {errorMessage && (
            <StyledAlert severity={'error'} icon={false}>
              {errorMessage}
            </StyledAlert>
          )}
          <StyledForm onSubmit={onSubmit} >
            <FormControl required error={!!errorMessage}>
              <TextFieldLabel htmlFor="email">
                Email
              </TextFieldLabel>
              <TextField
                id="email"
                type="text"
                autoFocus
                autoComplete="username email"
                aria-describedby="email"
                onChange={handleEmailChange}
                fullWidth
              />
            </FormControl>
            <FormControl required error={!!errorMessage} >
              <StyledTextFieldLabel htmlFor="password" >
                Password
              </StyledTextFieldLabel>
              <TextField
                id="password"
                type="password"
                autoComplete="current-password"
                aria-describedby="password"
                onChange={handlePasswordChange}
                fullWidth
              />
            </FormControl>
            <StyledLink href="/reset_password">
              Forgot your password?
            </StyledLink>
            <StyledSubmitButton
              primary
              fullWidth
              type="submit"
              disabled={loading}
            >
              Login
            </StyledSubmitButton>
          </StyledForm>
          {process.env.REACT_APP_ANDROID_APP_URL && (
            <>
              <StyledDivider sx={{ marginTop: pxToRem(24), marginBottom: pxToRem(16) }}>
                <span>
                  <Typography variant="body1">
                    OR
                  </Typography>
                </span>
              </StyledDivider>
              <TextFieldLabel sx={{ width: '100%', textAlign: 'start' }}>
                Download Customer App
              </TextFieldLabel>
              <Button
                fullWidth
                outlined
                startIcon={
                  <img src={'/svgs/android.svg'} width={36} height={36} />
                }
                href={process.env.REACT_APP_ANDROID_APP_URL || ''}
              >
                Download Android App
              </Button>
            </>
          )}
        </LoginCard>
      </StyledLoginPage>
    </LoginRoute>
  )
}

const StyledLoginPage = styled('main')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.background.default};

  ${props => props.theme.breakpoints.up('sm')} {
    height: 100vh;
  }
`

const StyledForm = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-top: ${props => props.theme.typography.pxToRem(12)};
  text-align: right;
  
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(4)};
  }
`

const StyledSubmitButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(28)};
  }
`

const StyledAlert = styled(Alert)`
  align-self: stretch;
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
`

const StyledTextFieldLabel = styled(TextFieldLabel)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};
`
