import { Tooltip, TooltipProps } from '@mui/material'
import { ToggleButtonProps as BaseToggleButtonProps } from '@mui/material/ToggleButton'

import { StyledToggleButton } from './StyledToggleButton'

export type ToggleButtonProps = Omit<BaseToggleButtonProps, 'disableRipple'> & {
  primary?: boolean
  secondary?: boolean
  round?: boolean
  tooltipTitle?: string
  slotProps?: {
    tooltip?: Omit<TooltipProps, 'title' | 'children'>
  }
}


export const ToggleButton: React.FC<ToggleButtonProps> = ({
  primary,
  secondary,
  round,
  tooltipTitle,
  slotProps,
  ...props
}) => {
  const toggleButtonProps: BaseToggleButtonProps = { ...props }

  toggleButtonProps.disableRipple = true

  if (primary) {
    toggleButtonProps.color = 'primary'
  } else if (secondary) {
    toggleButtonProps.color = 'secondary'
  }

  return (
    <Tooltip title={tooltipTitle || ''} arrow {...slotProps?.tooltip}>
      <StyledToggleButton
        $round={round}
        {...toggleButtonProps}
      >
        {props.children}
      </StyledToggleButton>
    </Tooltip>
  )
}
