import { Modal } from 'src/components/Modal/Modal'
import { Booking } from 'src/components/OperationsCalander/CalendarBookingRow'
import { formatDateAndTime } from 'src/libs/formatDateAndTime'
import { isNullOrEmpty } from 'src/libs/isNullOrEmpty'
import { pxToRem } from 'src/styles/themes'

import { Grid, styled, Typography } from '@mui/material'

export type CalendarBookingDialogProps = {
  booking: Booking | null
  setBooking: (booking: Booking | null) => void
}

export const CalendarBookingDialog: React.FC<CalendarBookingDialogProps> = ({ booking, setBooking }) => {
  const startFormatDateTime = booking ? formatDateAndTime(booking.startDate, booking.startHour, booking.startMinute) : null
  const endFormatDateTime = booking ? formatDateAndTime(booking.endDate, booking.endHour, booking.endMinute) : null

  return (
    <Modal
      title={'Booking Details'}
      open={booking !== null}
      onClose={() => setBooking(null)}
      slotProps={{
        containerProps: {
          sx: {
            maxWidth: pxToRem(560),
          },
        },
      }}
    >
      <Grid
        container
        gap={1}
      >
        {booking?.vehicleName && <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Vehicle Name:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{booking?.vehicleName}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>}

        <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Member:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{isNullOrEmpty(booking?.customerName) ? 'Anonymous' : booking?.customerName}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Start Date Time:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{startFormatDateTime}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>End Date Time:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{endFormatDateTime}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>

        {booking?.locationPickupName && <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Pickup Location:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{booking?.locationPickupName}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>}

        {booking?.locationDropoffName && <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Dropoff Location:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{booking?.locationDropoffName}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>}

        {booking?.bookingDestination && <Grid item sx={{ width: '100%' }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={'body1'}>Booking Destination:</Typography>
            </Grid>
            <Grid item>
              <StyledMediumTypography variant={'body1'}>{booking?.bookingDestination}</StyledMediumTypography>
            </Grid>
          </Grid>
        </Grid>}

      </Grid>
    </Modal>
  )
}


const StyledMediumTypography = styled(Typography)`
  font-weight: 500;
`